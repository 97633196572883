// Generated by Anima (Figma to React.ts)
import React from 'react';
import PropTypes from 'prop-types';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button, Dropdown, Row } from 'react-bootstrap';
import SupabaseService from '../services/SupabaseService';
import IconUser from '../icons/IconUser';
import IconUserPlus from '../icons/IconUserPlus';
import '../styles/HeaderAuth.css';

interface Props {
  state: 'logged-out' | 'logged-in';
}

export const HeaderAuth = ({ state }: Props): JSX.Element => {
  const handleLogout = async () => {
    await SupabaseService.logout();
  };
  return (
    <div className={`header-auth ${state}`}>
      {state === 'logged-out' && (
        <div>
          <Row className="justify-content-center">
            <Button
              variant="outline-light"
              as={
                Link as
                  | (ForwardRefExoticComponent<
                      LinkProps & RefAttributes<HTMLAnchorElement>
                    > &
                      keyof JSX.IntrinsicElements)
                  | undefined
              }
              to="/login"
              className="header-auth-button me-3"
            >
              <IconUser className="" color="#FFFFFF" size={16} /> Log in
            </Button>
            <Button
              variant="outline-light"
              as={
                Link as
                  | (ForwardRefExoticComponent<
                      LinkProps & RefAttributes<HTMLAnchorElement>
                    > &
                      keyof JSX.IntrinsicElements)
                  | undefined
              }
              to="/register"
              className="header-auth-button me-3"
            >
              <IconUserPlus className="" color="#FFFFFF" size={16} /> Register
            </Button>
          </Row>
        </div>
      )}

      {state === 'logged-in' && (
        <Dropdown align="end">
          <Dropdown.Toggle
            variant="outline-light"
            id="dropdown-basic"
            className="my-account-icon-container"
          >
            <IconUser className="my-account-icon" color="#FFFFFF" size={48} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleLogout}>Log Out</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

HeaderAuth.propTypes = {
  state: PropTypes.oneOf(['logged-out', 'logged-in']),
  text: PropTypes.string,
};
