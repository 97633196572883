import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';
import '../styles/Home.css';
import useDashboardData from '../hooks/useDashboardData';
const HeaderPath = '/assets/polytrack-header.jpg';
const FilteringPath = '/assets/polytrack-filtering.png';
const trainPath = '/assets/train-side.png';

const Home: React.FC = () => {
  const { session } = useDashboardData();
  return (
    <Layout>
      <div className="header-wrapper">
        <div className="header-container">
          <h1 className="header__text">PolyTrack</h1>
          <img
            src={HeaderPath}
            alt="polytrack-header.jpg"
            className="header__background"
          ></img>
        </div>
      </div>
      <div className="main-content">
        <div className="button_text"> Access our service here</div>
        <div className="button-header">
          <Link
            to={session ? '/Dashboard' : '/Login'}
            className="gradient-button"
          >
            <div className="button-text">PolyTrack</div>
          </Link>
        </div>
        <div className="intro-container">
          <h2 className="intro__title">What is PolyTrack?</h2>
          <p className="intro__text">
            PolyTrack is a maintenance tool for train/tram operators, using
            bogie-mounted sensor technology aboard passenger vehicles to analyse
            track health. This technology is currently in use on West Midlands
            Metro trams.
          </p>
        </div>

        <div className="polytrack-train-container">
          <div className="polytrack-train__image-container">
            <div className="polytrack-train__image__legend">
              <span
                className="polytrack-train__image__legend__sensor"
                id="polytrack-vis__image__sensor-1"
              ></span>
              <p className="polytrack-train__image__legend__text">
                Sensors to Measure Vibration, Shift, and Yaw
              </p>
            </div>
            <div className="polytrack-train__image__vis-container">
              <img
                src={trainPath}
                alt="train-side.png"
                className="polytrack-vis__image__train"
              ></img>
            </div>
          </div>
          <div className="polytrack-train__info-container">
            <p className="polytrack-train__info__text">
              PolyChord analyses sensor data to supply maintenance crews with
              actionable information on the health of the track, enabling
              targeted maintenance strategies which reduce down-time of service
              and overall maintenance costs. Using cutting-edge data science,
              PolyTrack creates an app of likely areas of damage or increased
              risk, which can be accessed by maintenance crews on a regular
              smartphone. This enables targeted maintenance, replacing laborious
              and hazardous manual checking of the full track and the
              potentially detrimental &#39;blanket&#39; maintenance with tamping
              machines.
            </p>
          </div>
        </div>

        <div className="polytrack-filtering-container">
          <div className="polytrack-filtering__image-container">
            <img
              src={FilteringPath}
              alt="placeholder.png"
              className="polytrack-filtering__image"
            ></img>
            <p className="img-description">
              PolyTrack Computing Accelerometer Outiers
            </p>
          </div>
          <div className="polytrack-filtering__info">
            <h2 className="polytrack-filtering__title">
              Filtering Out The Noise
            </h2>
            <p className="polytrack-filtering__text">
              Bogie-mounted sensors collect useful, but messy data. PolyChord
              explores the data landscape to make judgements about the surface
              and texture of the rail infrastructure. These judgements are then
              compared against the “real-world” physical geometry of the rail.
              Using time-series data mapping, PolyChord computes this data to
              make judgements about where similar faults will occur in the
              future.
            </p>
          </div>
        </div>

        <div className="outro-container">
          <h2 className="outro__title">Real-Time Maintenance</h2>
          <p className="outro__text">
            Using 5G technology from Comera and British Telecom, the aim of this
            project is to give near real-time information on the health of the
            track, reducing downtime of service, use of expensive equipment, and
            the manual intervention necessary in more traditional track
            maintenance methods.
          </p>
          <p className="outro-paragraph-text">
            We are currently seeking a commercial partner in the rail industry,
            to adjust PolyTrack to be suitable for use on traintracks as well as
            trams. To learn more, head to our{' '}
            <a href="contact.php" className="link">
              contact us
            </a>{' '}
            page and shoot us a message for more details.
          </p>
        </div>
      </div>
    </Layout>
  );
};
export default Home;
