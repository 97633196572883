import React, { Routes, Route, Navigate } from 'react-router-dom';

// import all pages here
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Authenticate from './pages/Authenticate';

const AllRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/authenticate" Component={Authenticate} />
    </Routes>
  );
};
export default AllRoutes;
