import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Link,
  Container,
  Paper,
  CircularProgress,
  Divider,
} from '@mui/material';
import Layout from '../components/Layout';
import SupabaseService from '../services/SupabaseService';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setSuccess(null);

    if (!email) {
      setLoading(false);
      return;
    }

    try {
      await SupabaseService.sendMagicLink(email);
      setSuccess(
        'If you have an account with us, you will receive a login link. Please check your inbox.',
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Container maxWidth="xs">
        <Paper elevation={1} sx={{ padding: 4, marginTop: 8, borderRadius: 2 }}>
          <Typography
            variant="h5"
            align="center"
            fontWeight="bold"
            gutterBottom
          >
            Sign In to PolyTrack
          </Typography>
          <Divider sx={{ marginY: 2 }} />
          <form onSubmit={handleLogin}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Email Address"
                type="email"
                value={email}
                onChange={handleInputChange}
                fullWidth
                required
                InputProps={{
                  sx: { borderRadius: 1 },
                }}
              />
              <Button
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                  paddingY: 1.5,
                  borderRadius: 2,
                  fontWeight: 'bold',
                  backgroundColor: '#5865f2',
                  '&:hover': { backgroundColor: '#4b55c1' },
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Sign In'
                )}
              </Button>
              {success && (
                <Typography color="success.main" align="center" mt={1}>
                  {success}
                </Typography>
              )}
              <Divider sx={{ marginY: 2 }} />
              <Typography align="center" variant="body2">
                <Link
                  href="../register"
                  underline="hover"
                  sx={{
                    color: 'inherit',
                    '&:hover': { color: '#5865f2' },
                  }}
                >
                  Do not have an account with us? Register here
                </Link>
              </Typography>
            </Box>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Login;
