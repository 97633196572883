import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SupabaseService from '../services/SupabaseService';

const Authenticate: React.FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleMagicLink = async () => {
      const url = new URL(window.location.href);
      const token = url.searchParams.get('token');
      const type = url.searchParams.get('type');

      if (token && type) {
        const { error } = await SupabaseService.verifyMagicLink(token);
        if (error) {
          console.error('Error verifying magic link:', error.message);
          setError('Error verifying magic link. Please try again.');
        } else {
          navigate('/dashboard');
        }
      } else {
        setError('Invalid or expired magic link.');
      }
    };

    handleMagicLink();
  }, [navigate]);

  return (
    <div>
      {error ? <div>Error: {error}</div> : <div>Verifying magic link...</div>}
    </div>
  );
};

export default Authenticate;
