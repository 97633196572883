import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/Map.css';

// Check for .env variable to render the map
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';
if (!mapboxgl.accessToken) {
  throw new Error(
    'Please set a valid REACT_APP_MAPBOX_TOKEN in your .env file',
  );
}

// POI interface solely for rendering markers on the map
interface PointOfInterest {
  Latitude: number;
  Longitude: number;
}

interface MapProps {
  pointsOfInterest: PointOfInterest[];
}

const INITIAL_CENTER: [number, number] = [-1.89983, 52.48624];
const INITIAL_ZOOM = 5.5;

const Map: React.FC<MapProps> = ({ pointsOfInterest }) => {
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);

  const [center, setCenter] = useState<[number, number]>(INITIAL_CENTER);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);

  useEffect(() => {
    // Initialize the Mapbox map
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: INITIAL_CENTER,
      zoom: INITIAL_ZOOM,
    });

    // Add navigation controls
    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Load vector source and layer for road infrastructure
    mapRef.current.on('load', () => {
      mapRef.current!.addSource('mapbox-streets', {
        type: 'vector',
        url: 'mapbox://mapbox.mapbox-streets-v8',
      });

      // Add roads layer while filtering for rail types
      mapRef.current!.addLayer(
        {
          id: 'roads-layer',
          type: 'line',
          source: 'mapbox-streets',
          'source-layer': 'road',
          filter: [
            'in',
            'class',
            'major_rail',
            'minor_rail',
            'rail',
            'light_rail',
            'subway',
            'tram',
            'funicular',
            'monorail',
            'narrow_gauge',
            'preserved',
            'construction',
          ],
          // Add colors for each type of rail, with firebrick (earth brown) as a fallback
          paint: {
            'line-color': [
              'match',
              ['get', 'class'],
              'major_rail',
              '#FF4500', // Major rail - vivid orange-red
              'minor_rail',
              '#9400D3', // Minor rail - dark violet
              'rail',
              '#1E90FF', // General rail - dodger blue
              'light_rail',
              '#FF1493', // Light rail - deep pink
              'subway',
              '#FF8C00', // Subway - dark orange
              'tram',
              '#00CED1', // Tram - dark turquoise
              'funicular',
              '#FFD700', // Funicular - gold
              'monorail',
              '#8A2BE2', // Monorail - blue violet
              'narrow_gauge',
              '#C71585', // Narrow gauge - medium violet red
              'preserved',
              '#32CD32', // Preserved - lime green
              'construction',
              '#FF0000', // Construction - bright red
              '#B22222', // Fallback color - firebrick
            ],
            'line-width': 2,
          },
          layout: {
            'line-cap': 'round',
            'line-join': 'round',
          },
        },
        'waterway',
      );

      // Add markers for points of interest
      pointsOfInterest.forEach((poi) => {
        if (poi.Latitude && poi.Longitude) {
          new mapboxgl.Marker()
            .setLngLat([poi.Longitude, poi.Latitude])
            .addTo(mapRef.current!);
        }
      });

      // Add click event listener for displaying popup with road name
      mapRef.current!.on('click', 'roads-layer', (e) => {
        const features = e.features;

        if (features && features.length > 0) {
          const roadName = features[0].properties?.class || 'Unnamed road';

          new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(`<p>Road Name: ${roadName}</p>`)
            .addTo(mapRef.current!);
        }
      });

      // Change the cursor to pointer when hovering over the roads layer
      mapRef.current!.on('mouseenter', 'roads-layer', () => {
        mapRef.current!.getCanvas().style.cursor = 'pointer';
      });

      mapRef.current!.on('mouseleave', 'roads-layer', () => {
        mapRef.current!.getCanvas().style.cursor = '';
      });
    });

    // Update map center and zoom state on map move
    mapRef.current.on('move', () => {
      const mapCenter = mapRef.current!.getCenter();
      const mapZoom = mapRef.current!.getZoom();
      setCenter([mapCenter.lng, mapCenter.lat]);
      setZoom(mapZoom);
    });

    // Cleanup on component unmount
    return () => {
      mapRef.current!.remove();
    };
  }, [pointsOfInterest]);

  // Reset map to initial center and zoom
  const handleReset = () => {
    mapRef.current!.flyTo({ center: INITIAL_CENTER, zoom: INITIAL_ZOOM });
  };

  return (
    <div className="map-wrapper">
      {/* Map container */}
      <div ref={mapContainerRef} className="map-container" />

      {/* Overlay bar with coordinates, zoom, and reset button */}
      <div className="map-overlay">
        <div>
          Longitude: {center[0].toFixed(4)} | Latitude: {center[1].toFixed(4)} |
          Zoom: {zoom.toFixed(2)}
        </div>
        <button onClick={handleReset} className="reset-button">
          Reset
        </button>
      </div>
    </div>
  );
};

export default Map;
