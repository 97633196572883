import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import SupabaseService from '../services/SupabaseService';

type AuthState = 'logged-out' | 'logged-in';

interface AuthContextProps {
  state: AuthState;
  isInitialized: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<AuthState>('logged-out');
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const fetchSession = async () => {
      const {
        data: { session },
      } = await SupabaseService.getAuth().getSession();
      setState(session ? 'logged-in' : 'logged-out');
      setIsInitialized(true);
    };

    fetchSession();

    const { data: authListener } = SupabaseService.getAuth().onAuthStateChange(
      (_event, session) => {
        setState(session ? 'logged-in' : 'logged-out');
      },
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ state, isInitialized }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
