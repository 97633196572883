import React, { useState } from 'react';
import Layout from '../components/Layout';
import useDashboardData from '../hooks/useDashboardData';
import Map from '../components/Map';
import '../styles/Dashboard.css';

const Dashboard: React.FC = () => {
  // Retrieve session data and POI data using useDashboardData()
  const { session, pointsOfInterest } = useDashboardData();
  const email = session?.user?.email;

  // Format points of interest data, excluding Supabase's internal fields `createdAt` and `ID`
  const formattedPoints = pointsOfInterest
    .map((poi) => ({
      id: poi.id.toString(),
      Latitude: Number(poi.Latitude),
      Longitude: Number(poi.Longitude),
      createdAt: poi.created_at,
      rank: Number(poi.Rank),
      stdError: poi['Standard Deviation of Error (m)'],
      dateFirstAppeared: poi['Date POI First appeared'],
      notes: poi.Notes,
    }))
    .sort((a, b) => a.rank - b.rank);

  // Toggling functionality between map and data views
  // This dictates whether the user sees the map or the data table
  // The default view is the map view
  const [isMapView, setIsMapView] = useState(true);
  const toggleView = () => {
    setIsMapView((prev) => !prev);
  };

  return (
    <Layout>
      <h1>Dashboard</h1>
      {email && <h2>Hello, {email}</h2>}

      {session && (
        <>
          {/* Show the view title and toggle button only if the user is logged in */}
          <h3>{isMapView ? 'Map View' : 'Data View'}</h3>
          <div className="dashboard-toggle">
            <button onClick={toggleView}>
              {isMapView ? 'Switch to Data View' : 'Switch to Map View'}
            </button>
          </div>
        </>
      )}

      {session ? (
        isMapView ? (
          // Map View: display POIs on a map
          <Map pointsOfInterest={formattedPoints} />
        ) : (
          // Data View: display POI data in  table format
          <div className="dataView">
            <table>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Standard Deviation of Error (m)</th>
                  <th>Date First Appeared</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {formattedPoints.map((poi) => (
                  <tr key={poi.id}>
                    <td>{poi.rank}</td>
                    <td>{poi.Latitude}</td>
                    <td>{poi.Longitude}</td>
                    <td>{poi.stdError}</td>
                    <td>{poi.dateFirstAppeared}</td>
                    <td>{poi.notes}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )
      ) : (
        // If the user is logged out, prompt a login
        <h3>Please log in to view the dashboard</h3>
      )}
    </Layout>
  );
};

export default Dashboard;
